import { Link } from "react-router-dom";
import "./navbar.scss";
import { useState, useEffect } from "react";
import useAuth from '../../../hooks/useAuth';
// import Environment from '@/utils/Enviroment';
import axios from 'axios';
import { toast } from "react-toastify";
import { setupNetwork } from "../../../utils/wallet";

import Modal from 'react-bootstrap/Modal';
import { useWeb3React } from '@web3-react/core';
import useWeb3 from '../../../hooks/useWeb3';
import { Offcanvas } from "react-bootstrap";
const Navbar = ({ handleShow, show, setShow }) => {
  let { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  // console.log("account here is", account, chainId)

  const { login, logout } = useAuth();
  const handleClose = () => setShow(false);

  const connectWallet = async (e) => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      await login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      handleClose();
      // setclickedbtn(false)
      // setLoader(false);
    }
    // setLoader(false);
  };

  const trustWallet = async (e) => {
    handleClose();
    try {
      if (account) {
        await logout("walletconnect");
      } else {
        login("walletconnect", e);
        localStorage.setItem('connectorId', 'walletconnect');
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error('Error during WalletConnect operation:', error);
      toast.error('An error occurred during WalletConnect operation');
    }
  };

  const disconnectWallet = async () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId)
    localStorage.removeItem('connectorId')
    localStorage.removeItem('flag')
    localStorage.removeItem('chain')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('wallet')
  };


  useEffect(() => {
    // Update the timer every second
    if (chainId != 1) {
      setupNetwork(1)
    }
  }, [chainId]);


  const [showoff, setShowoff] = useState(false);

  const handleCloseoff = () => setShowoff(false);
  const handleShowoff = () => setShowoff(true);


  const [show111, setShow111] = useState(false);
  const handleClose111 = () => setShow111(false);
  const handleShow111 = () => setShow111(true);



  return (
    <>
      <section className="main-navbar">
        <div className="nav-container">
          <nav class="navbar navbar-expand-xl">
            <Link class="navbar-brand" to="/"><img src="\logo.svg" alt="img" className="img-fluid" /></Link>
            <div className="twice-btns">
              <a onClick={handleShow111} className="howitworks-btn">how it works?</a>
              {/* <Link to="/faqs" className="faqs-btn">FAQs</Link> */}
              {account ?
                (
                  <button onClick={disconnectWallet} className="btn-disconnect">
                    {account?.slice(0, 8)}...
                    {account?.slice(
                      account?.length - 3,
                      account?.length
                    )}
                  </button>
                )
                :
                (
                  <button onClick={handleShow} className="btn-launch">
                    Connect Wallet
                  </button>
                )
              }
            </div>
            <a onClick={handleShowoff} className="bar-btn d-none"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3 7H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
              <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
              <path d="M3 17H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
            </svg></a>


          </nav>
        </div>
      </section>
      <Modal className="generic-modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="walletmains">
            <div className="walletinner sddsdsdsdsdsds" onClick={connectWallet} >
              <img src="\assets\metamaskimg.svg" alt="walletimg" className="walletimg" />
              <p className="walletpara">Metamask</p>
            </div>
            <div className="walletinner" onClick={() => {
              trustWallet();
              setShowoff(false);
            }}>
              <img src="\assets\walletconnectimg.svg" alt="walletimg" className="walletimg" />
              <p className="walletpara">WalletConnect</p>
            </div>
          </div>
          {/* <button className="commonbtn">OKAY</button>
          <div className="mainbtns">
          <button className="dullbtn">Cancel</button>
          <button className="brdrbtn">Confirm</button>
          </div> */}
        </Modal.Body>
      </Modal>


      <Offcanvas className="offcanvas-bar" show={showoff} onHide={handleCloseoff}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <a href="/"><img src="\logo.svg" alt="img" className="img-fluid" /></a> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="multi-btns">
            <a onClick={handleShow111} className="faqs-btn">how it works?</a>
            {/* <Link to="/faqs" className="faqs-btn">FAQs</Link> */}
            {account ?
              (
                <button onClick={disconnectWallet} className="btn-disconnect">
                  {account?.slice(0, 8)}...
                  {account?.slice(
                    account?.length - 3,
                    account?.length
                  )}
                </button>
              )
              :
              (
                <button onClick={handleShow} className="btn-launch">
                  Connect Wallet
                </button>
              )
            }
          </div>
        </Offcanvas.Body>
      </Offcanvas>


      <Modal
        className="generic-modal stakingmodals howitworksmodal"
        show={show111}
        onHide={handleClose111}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to the GEMS Staking Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="howitworks-content">
            <h5>Here's how it works:</h5>
            <ul>
              <li>Start earning rewards as soon as you stake your tokens.</li>
              <li>You can unstake your tokens whenever you want, but they will only be released after a 7-day waiting period. Please note that rewards will stop accumulating once you request to unstake.</li>
              <li>Rewards can be claimed after a 90-day lockup period, which starts from the initial staking date.</li>
              <li>To compound your earnings, you can restake your rewards. However, this will initiate a new 90-day lockup period for the restaked rewards.</li>
            </ul>
            <h6>Important information about the Annual Percentage Yield (APY):</h6>
            <p className="para">The APY is dependent on the total number of tokens staked in the system and is subject to constant fluctuations. For instance, if the initially offered APY is 200%, it may decrease to 100% if the total amount of staked tokens doubles.</p>
            <p className="para"> If you have any further questions, please don't hesitate to reach out to our support team. Happy staking!</p>



          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;


import React, { useState, useEffect } from "react";
import "./banner.scss";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Stats from "../../Stats/Stats";
import Mystaking from "../../Mystaking/Mystaking";
import Myunstaking from "../../Myunstaking/Myunstaking";
import Leaderremarks from "../../Leaderremarks/Leaderremarks";
import { useWeb3React } from "@web3-react/core";
import Modal from "react-bootstrap/Modal";
import Navbar from '../header/Navbar';
import { Link } from "react-router-dom";

const Banner = () => {
  const { account } = useWeb3React();
  const indexvv = localStorage.getItem("indexvalue");
  const [indexwaits, setindexwaits] = useState('');
  const [apyvalue, setapyvalue] = useState('');
  const [openmodal, setOpenmodal] = useState(false)
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);





  const [inputFeild1, setInputFeild1] = useState(false);
  const inputField1Handler1 = (e) => {
    if (e.target.checked == true) {
      setInputFeild1(true);
    } else {
      setInputFeild1(false);
    }
  };


  const [show11, setShow11] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleClose11 = () => {
    setShow11(false);
    setAcceptedTerms(true);
    localStorage.setItem('acceptedTerms', 'true');
  };

  const handleShow11 = () => setShow11(true);

  useEffect(() => {
    const hasAcceptedTerms = localStorage.getItem('acceptedTerms');

    if (!hasAcceptedTerms) {
      handleShow11();
    }
  }, []);



  const onselecttab = (rolee) => {
    localStorage.setItem("indexvalue", rolee);
    setindexwaits(rolee)
  }
  // console.log("index wait", indexwait)
  const [indexwait, setindexwait] = useState(0);
  useEffect(() => {
    if (indexvv == "MYSTAKING") {
      setindexwait(0)
    }
    else if (indexvv == "MYUNSTAKING") {
      setindexwait(1)
    }
    else if (indexvv == "LEADER") {
      setindexwait(2)
    }
  }, [indexwaits])

  // console.log("indev ", indexwait)
  return (
    <>
      <Navbar handleShow={handleShow} show={show} setShow={setShow} />
      <div className="forheightset">
        <section className="main-banner">
          <img src="\assets\bannerbg.png" alt="bannerbg" className="banner-bg" />
          <img src="\assets\global-bg.png" alt="img" className="img-fluid global-bg" />
          {/* <img src="\assets\bannerbgmbl.png" alt="bannerbg" className="banner-bg-mbl d-none" /> */}
          <div className="custom-container" >
            <div className="parent">
              <div className="bannerheadings">
                <h4 className="bannerhead">GEMS STAKING</h4>
                <h2>Earn rewards by staking your GEMS tokens and watch your rewards grow!</h2>
                <p className="bannerpara">Start enjoying rewards as soon as you stake your GEMS tokens.</p>
                <div className="twice-btns">
                  {/* {
                    account ?
                      (
                        <a href="/staking" className="stake-btn">stake $GEMS</a>
                      )
                      :
                      (
                        <a onClick={() => { handleShow8() }} className="stake-btn">stake $GEMS</a>
                      )
                  } */}
                  {/* <a onClick={handleShow111} className="howitworks-btn">how it works?</a> */}
                </div>
              </div>
            </div>
            <div className="stats-new-style">
              <Stats apyvalue={apyvalue} setapyvalue={setapyvalue} />
            </div>
          </div>
        </section>
        <section className="tabsmain">
          <div className="custom-container">
            <Tabs
              activeKey={indexwait === 0 ? "MYSTAKING" : indexwait === 1 ? "MYUNSTAKING" : "LEADER"}
              id="uncontrolled-tab-example"
              transition={false}
              onSelect={onselecttab}
            >
              <Tab eventKey="MYSTAKING" title="MY STAKING">
                <Mystaking indexwaits={indexvv} indexwait={indexwait} openmodal={openmodal} setOpenmodal={setOpenmodal} setShow111={setShow} />
              </Tab>
              <Tab eventKey="MYUNSTAKING" title="MY UNSTAKING">
                <Myunstaking indexwaits={indexvv} setShow111={setShow} />
              </Tab>
              {/* <Tab eventKey="LEADER" title="LEADER REWARDS">
              <Leaderremarks indexwaits={indexvv} />
            </Tab> */}
            </Tabs>
          </div>
        </section>
      </div>
      <Modal
        className="generic-modal stakingmodals"
        show={show8}
        onHide={handleClose8}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Wallet Connect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\vector.png"
              alt="img"
              className="img-fluid 
formsrgin"
            />

            <p className="wrong">Please Connect Your Wallet First</p>
            <button className="commonbtn" onClick={handleClose8}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>



      <Modal
        className="generic-modal stakingmodals howitworksmodal"
        show={show11}
        onHide={handleClose11}
        centered
        backdrop={false}
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Welcome to the GEMS Staking Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="howitworks-content">
            <h5>Here's how it works:</h5>
            <ul>
              <li>Start earning rewards as soon as you stake your tokens.</li>
              <li>You can unstake your tokens whenever you want, but they will only be released after a 7-day waiting period. Please note that rewards will stop accumulating once you request to unstake.</li>
              <li>Rewards can be claimed after a 90-day lockup period, which starts from the initial staking date.</li>
              <li>To compound your earnings, you can restake your rewards. However, this will initiate a new 90-day lockup period for the restaked rewards.</li>
            </ul>
            <h6>Important information about the Annual Percentage Yield (APY):</h6>
            <p className="para">The APY is dependent on the total number of tokens staked in the system and is subject to constant fluctuations. For instance, if the initially offered APY is 200%, it may decrease to 100% if the total amount of staked tokens doubles.</p>
            <p className="para"> If you have any further questions, please don't hesitate to reach out to our support team. Happy staking!</p>
            <div className="mycheckbox">
              <label class="innercheckbox" for="firstinput">
                <input
                  onChange={inputField1Handler1}
                  value={inputFeild1}
                  type="checkbox"
                  name="color"
                  id="firstinput"
                />
                <span class="innercheckmark"></span>
              </label>
              <p className="innercheckboxpara">
                I agree with GEMS{" "}
                <Link to="/terms" target="_blank">Terms of Use</Link>
              </p>
            </div>
            {
              inputFeild1 ? <a onClick={handleClose11} className="btn-accept">Accept</a> : ""
            }

          </div>
        </Modal.Body>
      </Modal>



    </>
  );
};

export default Banner;

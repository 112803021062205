import "./App.scss";
import Landing from "./components/landing/Landing.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useEagerConnect from "./hooks/useEagerConnect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Faqs from "./components/Faqs/Faqs.js";
import Terms from "./components/Termofuse/Terms.js";
// import Mystaking from "./components/Mystaking2/Mystaking.js";

function App() {
  useEagerConnect();
  return (
    <>
      <ToastContainer className="" theme="" style={{ fontSize: 20 }} />
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/terms" element={<Terms />} />
          {/* <Route exact path="/faqs" element={<Faqs />} /> */}
          {/* <Route exact path="/staking" element={<Mystaking />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
